import React from "react";

interface INumberBoxParams {
  number: number | string | null;
  prefix?: string;
  suffix?: string;
  isInteger?: boolean;
  decimals?: number;
  ignoreZeroValue?: boolean;
  nothingForNull?: boolean;
  isSmallAmount?: boolean;
  adjustDecimals?: boolean;
}

const NumberBox = ({
  number,
  prefix = "",
  suffix = "",
  isInteger = false,
  ignoreZeroValue = false,
  nothingForNull = false,
  isSmallAmount = false,
  decimals = 2,
  adjustDecimals = false,
}: INumberBoxParams) => {
  const formatNumber = (number: number) => {
    const fixed = adjustDecimals && number > 10 ? 0 : decimals;
    return Number(number.toFixed(fixed)).toLocaleString();
  };

  if (
    number === null ||
    number === undefined ||
    (ignoreZeroValue && number == 0)
  ) {
    if (nothingForNull) return <></>;
    else return <>-</>;
  }

  number = Number(number);

  if (isInteger) number = Math.round(number);

  let isMinus = false;
  if (number < 0) {
    number = -1 * number;
    isMinus = true;
  }

  if (number === 0) {
    return (
      <>
        {prefix}0{suffix}
      </>
    );
  }

  if (number >= 1e12) {
    return (
      <>
        {isMinus ? "-" : ""}
        {prefix}
        {formatNumber(number / 1e12) + "T"}
        {suffix}
      </>
    );
  }

  if (number >= 1e9) {
    return (
      <>
        {isMinus ? "-" : ""}
        {prefix}
        {formatNumber(number / 1e9) + "B"}
        {suffix}
      </>
    );
  }

  if (isSmallAmount) {
    if (number >= 1e6) {
      return (
        <>
          {isMinus ? "-" : ""}
          {prefix}
          {formatNumber(number / 1e6) + "M"}
          {suffix}
        </>
      );
    }

    if (number >= 1e3) {
      return (
        <>
          {isMinus ? "-" : ""}
          {prefix}
          {formatNumber(number / 1e3) + "K"}
          {suffix}
        </>
      );
    }
  }

  if (number >= 0.01) {
    let fixed = decimals;
    if (decimals === 2) {
      if (number < 1) {
        const newValue = Number(number.toFixed(2));
        if (Math.abs(number - newValue) >= newValue * 0.01) fixed = 3;
      }
    }
    return (
      <>
        {isMinus ? "-" : ""}
        {prefix}
        {Number(number.toFixed(fixed)).toLocaleString()}
        {suffix}
      </>
    );
  }

  const zeroCnt = (1 / number).toFixed(0).toString().length - 1;
  let numberPart = number * Math.pow(10, zeroCnt + 3);
  if (numberPart % 100 === 0) numberPart = numberPart / 100;
  else if (numberPart % 10 === 0) numberPart = numberPart / 10;
  return (
    <>
      {isMinus ? "-" : ""}
      {prefix}
      0.0<span style={{ fontSize: "10px", marginTop: "5px" }}>{zeroCnt}</span>
      {numberPart.toFixed(0)}
      {suffix}
    </>
  );
};

export default NumberBox;
