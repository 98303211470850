import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  IAdvancedFilterQuery,
  IDexTransactions,
  ISmartMoneyCategories,
  ISmartMoneyDexTransactionsQuery,
} from "../../@types/redux";
import { SmartMoneyAPI } from "../../utils/api/SmartMoneyAPI";

export interface ISmartMoneyState {
  isRefreshing: boolean;
  loading: boolean;
  total: number;
  transactions: [];
  query: {
    page: number;
    page_size: number;
    total: boolean;
    ordering: string;
    smart_money_categories: string[];
    advanced_filter?: IAdvancedFilterQuery;
  };
}

const advancedFilterDefaultQuery = {
  trade_token_addresses: [],
  trader_address: "",
  min_amount_usd: null,
  max_amount_usd: null,
  apply_filter: false,
} as IAdvancedFilterQuery;

export const smartMoneyDefaultQuery = {
  page: 1,
  page_size: 50,
  total: false,
  ordering: "",
  smart_money_categories: [],
  advanced_filter: advancedFilterDefaultQuery,
};

const initialState: ISmartMoneyState = {
  isRefreshing: false,
  loading: false,
  total: 0,
  transactions: [],
  query: {
    ...smartMoneyDefaultQuery,
  },
};

const smartMoneySlice = createSlice({
  name: "smartMoney",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<ISmartMoneyDexTransactionsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.transactions = [];
      }
    },

    setList: (state, action: PayloadAction<IDexTransactions>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total = action.payload.total;
      state.transactions = action.payload.transactions;
    },

    setAdvancedFilterQuery: (
      state,
      action: PayloadAction<IAdvancedFilterQuery>
    ) => {
      state.query.advanced_filter = action.payload;
    },

    clearAdvancedFilters: (state, _) => {
      state.query.advanced_filter = advancedFilterDefaultQuery;
    },

    setQuery: (
      state,
      action: PayloadAction<ISmartMoneyDexTransactionsQuery>
    ) => {
      state.query.page_size = action.payload.page_size;
      state.query.page = action.payload.page;
      state.query.ordering = action.payload.ordering;
      state.query.smart_money_categories =
        action.payload.smart_money_categories;
    },

    setSmartMoneyCategory: (
      state,
      action: PayloadAction<ISmartMoneyCategories>
    ) => {
      state.query = {
        ...smartMoneyDefaultQuery,
        advanced_filter: state.query.advanced_filter,
        smart_money_categories: action.payload.smart_money_categories,
      };
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...smartMoneyDefaultQuery,
        advanced_filter: state.query.advanced_filter,
        smart_money_categories: state.query.smart_money_categories,
      };
    },
  },
});

const smartMoneyActions = smartMoneySlice.actions;
const smartMoneyReducer = smartMoneySlice.reducer;

export { smartMoneyActions, smartMoneyReducer };

// Sagas

function* getDexTransactions(action: any): any {
  const dexTransactionsRes = yield call(SmartMoneyAPI.getDexTransactions, {
    ...action.payload,
  });

  if (!dexTransactionsRes.error) {
    yield put(
      smartMoneyActions.setList({
        transactions: dexTransactionsRes.data.results,
        total: dexTransactionsRes.data.total_count,
      })
    );
  } else {
    yield put(
      smartMoneyActions.setList({
        transactions: [],
        total: 0,
      })
    );
  }
}

export function* smartMoneySaga() {
  yield all([takeLatest(smartMoneyActions.getList, getDexTransactions)]);
}
