import {
  IChainQuery,
  IGetHotTrendingQuery,
  IGetNewTGProfilesQuery,
  IGetSmartMoneyQuery,
  IGetTopDexVolumeTokensParams,
  IGetTopTrendingTokensParams,
  INarrativePulseQuery,
  IStatisticsDexVolumeQuery,
  IStatisticsMarketCapQuery,
  ITopWhaleTradesParams,
} from "../../@types/api";
import { ITopTokenWhalesQuery } from "../../@types/redux";
import { Chains } from "../constants";
import { BaseAPI } from "./BaseAPI";

export class StatisticsAPI {
  static getMarketCapHistory = async ({
    num_days,
  }: IStatisticsMarketCapQuery) => {
    return BaseAPI.callAPI({
      url: "/statistics/market_cap_history/",
      params: {
        num_days,
      },
      needToken: false,
    });
  };

  static getDexVolume = async ({
    num_days,
    chain,
  }: IStatisticsDexVolumeQuery) => {
    return BaseAPI.callAPI({
      url: "/dex/statistics/volume/",
      params: {
        num_days,
        chain: chain === Chains.All ? null : chain,
      },
      needToken: false,
    });
  };

  static getDominance = async () => {
    return BaseAPI.callAPI({
      url: "/statistics/dominance/",
    });
  };

  static getWhaleDexTransactions = async ({
    chain,
    threshold,
    page,
    page_size,
    total_limit,
    exclude_ncg_tokens,
  }: ITopWhaleTradesParams) => {
    return BaseAPI.callAPI({
      url: "/dex/whale_transactions/",
      params: {
        chain: chain === Chains.All ? null : chain,
        threshold_value: threshold,
        page,
        page_size,
        total_limit,
        exclude_ncg_tokens,
      },
    });
  };

  static getMostActiveWallets = async ({ chain }: IChainQuery) => {
    return BaseAPI.callAPI({
      url: "/statistics/most_active_wallets/",
      params: { chain },
      needToken: false,
    });
  };

  static getTopGasWallets = async ({ chain }: IChainQuery) => {
    return BaseAPI.callAPI({
      url: "/statistics/top_gas_wallets/",
      params: { chain },
    });
  };

  static getNarrativePulse = async ({
    chain,
    page,
    page_size,
    ordering,
  }: INarrativePulseQuery) => {
    return BaseAPI.callAPI({
      url: "/narrative_pulse/",
      params: {
        chain: chain === Chains.All ? null : chain,
        page,
        page_size,
        ordering,
      },
      needToken: false,
    });
  };

  static getNarrativePulseTokens = async (category_id: string) => {
    return BaseAPI.callAPI({
      url: `/narrative_pulse/${category_id}/tokens/`,
      needToken: false,
    });
  };

  static getTopTrendingTokens = async ({
    chain,
    exclude_sn_tokens,
  }: IGetTopTrendingTokensParams) => {
    return BaseAPI.callAPI({
      url: "/tokens/top_trending/",
      params: {
        chain: chain === Chains.All ? null : chain,
        exclude_sn_tokens,
      },
      needToken: false,
    });
  };

  static getTopDexVolumeTokens = async ({
    chain,
    exclude_sn_tokens,
    exclude_ncg_tokens,
    num_hours,
    category,
    need_token,
    flow,
    page,
    page_size,
  }: IGetTopDexVolumeTokensParams) => {
    return BaseAPI.callAPI({
      url: "/tokens/top_dex_volume/",
      params: {
        chain: chain === Chains.All ? null : chain,
        exclude_sn_tokens,
        exclude_ncg_tokens,
        num_hours,
        category,
        flow,
        page,
        page_size,
      },
      needToken: need_token,
    });
  };

  static getNewTGProfiles = async ({
    chain,
    page,
    page_size,
  }: IGetNewTGProfilesQuery) => {
    return BaseAPI.callAPI({
      url: "/tokens/new_profiles/",
      params: {
        chain: chain === Chains.All ? null : chain,
        page,
        page_size,
      },
      needToken: false,
    });
  };

  static getHotTrendingTokens = async ({
    chain,
    page,
    page_size,
  }: IGetHotTrendingQuery) => {
    return BaseAPI.callAPI({
      url: "/tokens/hot_trending/",
      params: {
        chain: chain === Chains.All ? null : chain,
        page,
        page_size,
      },
      needToken: false,
    });
  };

  static getSmartMoneyFlows = async ({
    chain,
    page,
    page_size,
    num_hours,
    category,
    need_token,
    flow,
  }: IGetSmartMoneyQuery) => {
    return BaseAPI.callAPI({
      url: "/dex/statistics/sm_top_flows/",
      params: {
        chain: chain === Chains.All ? null : chain,
        page,
        page_size,
        num_hours,
        category,
        flow,
      },
      needToken: need_token,
    });
  };

  static getWhaleSegments = async ({
    category,
    chain,
    exclude_sn_tokens,
    num_hrs,
    threshold_value,
  }: ITopTokenWhalesQuery) => {
    return BaseAPI.callAPI({
      url: "/dex/statistics/whale_segments/",
      params: {
        category,
        chain: chain === Chains.All ? null : chain,
        exclude_sn_tokens,
        num_hrs,
        threshold_value,
      },
    });
  };

  static getTGStatistics = async ({ chain }: IChainQuery) => {
    return BaseAPI.callAPI({
      url: "/dex/statistics/overall/",
      params: {
        chain: chain === Chains.All ? null : chain,
      },
    });
  };
}
