import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IDexFlows, IDexFlowsQuery } from "../../@types/redux";
import { DexFlowsAPI } from "../../utils/api/DexFlowsAPI";

const initialState = {
  isRefreshing: false,
  loading: false,
  results: [],
  max_values: {
    buy_volume: 0,
    sell_volume: 0,
    net_volume: 0,
    market_cap: 0,
  },
};

const dexFlowsSlice = createSlice({
  name: "totalFlows",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<IDexFlowsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.results = [];
      }
    },

    setList: (state, action: PayloadAction<IDexFlows>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.results = action.payload.results;

      state.max_values = action.payload.results.reduce(
        (acc, result: any) => ({
          buy_volume: Math.max(
            acc.buy_volume,
            Math.abs(result.buy_volume || 0)
          ),
          sell_volume: Math.max(
            acc.sell_volume,
            Math.abs(result.sell_volume || 0)
          ),
          net_volume: Math.max(
            acc.net_volume,
            Math.abs(result.net_volume || 0)
          ),
          market_cap: Math.max(
            acc.market_cap,
            Math.abs(result.market_cap || 0)
          ),
        }),
        {
          buy_volume: 0,
          sell_volume: 0,
          net_volume: 0,
          market_cap: 0,
        }
      );
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
    },
  },
});

const dexFlowsAction = dexFlowsSlice.actions;
const dexFlowsReducer = dexFlowsSlice.reducer;

export { dexFlowsAction, dexFlowsReducer };

// Sagas

function* getDexFlows(action: PayloadAction<IDexFlowsQuery>): any {
  const res = yield call(DexFlowsAPI.getDexFlows as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      dexFlowsAction.setList({
        results: res.data,
      })
    );
  } else {
    yield put(
      dexFlowsAction.setList({
        results: [],
      })
    );
  }
}

export function* dexFlowsSaga() {
  yield all([takeLatest(dexFlowsAction.getList, getDexFlows)]);
}
