import { CircularProgress } from "@mui/material";
import React from "react";
import "./scss/LoadingContainer.scss";

interface ILoadingContainer {
  size?: number;
}

const LoadingContainer = ({ size = 24 }: ILoadingContainer) => {
  return (
    <div className="loading-container">
      <CircularProgress size={size} color="inherit" />
    </div>
  );
};

export default LoadingContainer;
