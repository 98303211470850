import React from "react";

const LatestUpdatesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      className="fill"
    >
      <g clipPath="url(#clip0_12049_117814)">
        <path
          d="M20.5267 4.2169L14.4997 0.736897C13.7391 0.299519 12.877 0.0693359 11.9997 0.0693359C11.1223 0.0693359 10.2602 0.299519 9.49966 0.736897L3.47266 4.2169C2.71353 4.65681 2.08313 5.28822 1.64443 6.04805C1.20573 6.80788 0.974092 7.66952 0.972656 8.5469V15.5069C0.974119 16.3844 1.20576 17.2462 1.64445 18.0062C2.08314 18.7661 2.71352 19.3977 3.47266 19.8379L9.49966 23.3169C10.2601 23.7546 11.1222 23.9849 11.9997 23.9849C12.8771 23.9849 13.7392 23.7546 14.4997 23.3169L20.5267 19.8379C21.2858 19.3977 21.9162 18.7661 22.3549 18.0062C22.7936 17.2462 23.0252 16.3844 23.0267 15.5069V8.5469C23.0252 7.66952 22.7936 6.80788 22.3549 6.04805C21.9162 5.28822 21.2858 4.65681 20.5267 4.2169ZM10.4997 2.4699C10.9557 2.20659 11.473 2.06797 11.9997 2.06797C12.5263 2.06797 13.0436 2.20659 13.4997 2.4699L19.5267 5.9489C19.6795 6.0455 19.8231 6.15596 19.9557 6.2789L13.7627 9.8539C13.2265 10.163 12.6185 10.3257 11.9997 10.3257C11.3808 10.3257 10.7728 10.163 10.2367 9.8539L4.04366 6.2789C4.17622 6.15596 4.31983 6.0455 4.47266 5.9489L10.4997 2.4699ZM4.47266 18.1049C4.01686 17.8408 3.63841 17.4617 3.37517 17.0054C3.11193 16.5491 2.97313 16.0317 2.97266 15.5049V8.5469C2.9796 8.36674 3.00337 8.18763 3.04366 8.0119L9.23666 11.5869C9.7836 11.898 10.3799 12.1128 10.9997 12.2219V21.7909C10.8269 21.738 10.6595 21.669 10.4997 21.5849L4.47266 18.1049ZM21.0267 15.5049C21.0262 16.0317 20.8874 16.5491 20.6241 17.0054C20.3609 17.4617 19.9825 17.8408 19.5267 18.1049L13.4997 21.5849C13.3398 21.669 13.1724 21.738 12.9997 21.7909V12.2219C13.6194 12.1128 14.2157 11.898 14.7627 11.5869L20.9557 8.0119C20.9959 8.18763 21.0197 8.36674 21.0267 8.5469V15.5049Z"
          fill="url(#paint0_linear_7748_25048)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7748_25048"
          x1="13.3094"
          y1="-2.18581e-05"
          x2="13.3094"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#10B3E8" />
          <stop offset="1" stopColor="#3F80E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LatestUpdatesIcon;
