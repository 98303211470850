import { Menu, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { TokenInfoAPI } from "../../utils/api/TokenInfoAPI";
import LoadingContainer from "./LoadingContainer";
import NumberBox from "./NumberBox";
import "./scss/ContextMenu.scss";

interface IContextMenu {
  children: React.ReactNode;
  handleClick: () => void;
  chain: string;
  address: string;
  showMarketCap?: boolean;
}

const ContextMenu: React.FC<IContextMenu> = ({
  children,
  handleClick,
  chain,
  address,
  showMarketCap = false,
}) => {
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [marketCap, setMarketCap] = React.useState<number | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getTokenMarketCap = async () => {
    setLoading(true);
    const res = await TokenInfoAPI.getMarketCapInfo({
      chain,
      token_address: address,
    });
    if (!res.error) setMarketCap(res.data.market_cap);
    setLoading(false);
  };

  useEffect(() => {
    if (contextMenu) getTokenMarketCap();
  }, [contextMenu]);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleClickMenu = () => {
    handleClick();
    handleClose();
  };

  return (
    <div onContextMenu={handleContextMenu}>
      {children}
      <Menu
        classes={{
          root: "context-menu",
        }}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClickMenu}>Token Genius</MenuItem>
        {showMarketCap && (
          <MenuItem>
            {loading ? (
              <>
                <span style={{ marginRight: "10px" }}>Market Cap:</span>
                <LoadingContainer size={16} />
              </>
            ) : marketCap ? (
              <>
                {"Market Cap: "}
                <NumberBox number={marketCap} prefix="$" isSmallAmount />
              </>
            ) : (
              "Market Cap: None"
            )}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ContextMenu;
