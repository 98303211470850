import { IGetSmartMoneyDexTxsParams } from "../../@types/api";
import { Chains } from "../constants";
import { BaseAPI } from "./BaseAPI";

export class SmartMoneyAPI {
  static getSmartMoneyCategories = async () => {
    return BaseAPI.callAPI({
      url: "/wallet/smart_money/categories/",
    });
  };

  static getDexTransactions = async ({
    chain,
    page = 1,
    page_size = 25,
    ordering = "",
    smart_money_categories = [],
    advanced_filter = {},
  }: IGetSmartMoneyDexTxsParams) => {
    return BaseAPI.callAPI({
      url: "/dex/smart_money_transactions/",
      params: {
        chain: chain === Chains.All ? null : chain,
        page,
        page_size,
        ordering,
        smart_money_categories:
          smart_money_categories.length === 0 ? null : smart_money_categories,
        min_amount_usd: advanced_filter?.min_amount_usd,
        max_amount_usd: advanced_filter?.max_amount_usd,
      },
    });
  };
}
