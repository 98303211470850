import { CircularProgress, Divider, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDisconnect } from "wagmi";
import { AuthHelpers } from "../../helpers/auth";
import { authActions } from "../../redux/features/authSlice";
import { sidebarActions } from "../../redux/features/sidebarSlice";
import { authStateSelector } from "../../redux/selectors/authSelectors";
import { AuthAPI } from "../../utils/api/AuthAPI";
// import { SubScrType } from "../../utils/constants";
import { LocalStorages } from "../../utils/localStorages";
import { AppRoutes } from "../../utils/routes";
import { Utils } from "../../utils/utils";
import Connector from "../Common/Connector";
import ArrowRightIcon from "../Icons/ArrowRightIcon";
import HamburgerIcon from "../Icons/HamburgerIcon";
import HelpCircleIcon from "../Icons/HelpCircleIcon";
import LatestUpdatesIcon2 from "../Icons/LatestUpdatesIcon2";
import MetaMaskIcon from "../Icons/MetaMaskIcon";
import MobileSearchIcon from "../Icons/MobileSearchIcon";
import SignOutIcon from "../Icons/SignOutIcon";
import UpgradeIcon2 from "../Icons/UpgradeIcon2";
import UserProfileIcon from "../Icons/UserProfileIcon";
import DarkLogo from "../Logos/DarkLogo";
import MobileLogo from "../Logos/MobileLogo";
import SearchBar from "./SearchBar";

import "./scss/index.scss";

interface IHeaderBar {
  hideSearchBar?: boolean;
}

const Header = ({ hideSearchBar }: IHeaderBar) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { disconnectAsync } = useDisconnect();
  const loading = useSelector(authStateSelector).loading;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [clickHandleConnect, setClickHandleConnect] = useState(false);
  const openMenu = Boolean(anchorEl);

  const isLoggedIn = AuthHelpers.isUserLoggedIn();
  // const subScrType = isLoggedIn ? LocalStorages.getSubScrType() : 0;

  const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget) setAnchorEl(event.currentTarget);
  };

  const handleDisconnect = async () => {
    await AuthAPI.signout();
    await disconnectAsync();
    dispatch(authActions.signout({}));
    location.reload();
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = () => {
    navigate(AppRoutes.getExactRoute(AppRoutes.profile));
  };

  const handleClickHelp = () => {
    window.open("https://docs.alphanomics.io/help/", "_blank");
    handleCloseProfileMenu();
  };

  const handleClickPlan = () => {
    navigate(AppRoutes.getExactRoute(AppRoutes.plan));
  };

  const handleClickLatestUpdates = () => {
    navigate(AppRoutes.getExactRoute(AppRoutes.latestUpdates));
  };

  const navigateToHome = () => {
    dispatch(sidebarActions.closeSidebar({}));
    setShowMobileSearch(false);
    navigate(AppRoutes.getExactRoute(AppRoutes.home));
  };

  const handleOpenSidebar = () => {
    dispatch(sidebarActions.openSidebar({}));
  };

  const handleSearch = () => {
    setShowMobileSearch(true);
  };

  const handleConnectWallet = async () => {
    setClickHandleConnect(true);
  };

  const renderMenuContainer = () => {
    return (
      <ul className="menu-container">
        {
          <>
            <li className="dm-sans-400 desktop">
              <Link
                to={AppRoutes.getExactRoute(AppRoutes.smartAlert)}
                className={`text-secondary-normal nexus`}
              >
                Smart Alerts
              </Link>
            </li>
          </>
        }
        <li>
          {isLoggedIn ? (
            <div className="profile-menu-container">
              <div className="wallet-address" onClick={handleOpenProfileMenu}>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <MetaMaskIcon />
                    <span>
                      {Utils.formatWalletAddress(
                        LocalStorages.getWalletAddress() as string
                      )}
                    </span>
                    <UserProfileIcon />
                  </>
                )}
              </div>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                className="dropdown-menu"
                open={openMenu}
                onClose={handleCloseProfileMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                classes={{
                  root: "menu-root-container",
                }}
                disablePortal
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleClickProfile} className="item">
                  <span className="icon">
                    <UserProfileIcon />
                  </span>
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleClickHelp} className="item">
                  <span className="icon">
                    <HelpCircleIcon />
                  </span>
                  Help
                </MenuItem>
                {isLoggedIn && (
                  <MenuItem onClick={handleClickPlan} className="item">
                    <span className="icon">
                      <UpgradeIcon2 />
                    </span>
                    My Plan
                  </MenuItem>
                )}
                <MenuItem onClick={handleClickLatestUpdates} className="item">
                  <span className="icon">
                    <LatestUpdatesIcon2 />
                  </span>
                  Latest Updates
                </MenuItem>
                <Divider />
                <MenuItem className="item" onClick={handleDisconnect}>
                  <span className="icon">
                    <SignOutIcon />
                  </span>
                  <span className="text-secondary-main dm-sans-500">
                    Disconnect
                  </span>
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <button className="btn-connect" onClick={handleConnectWallet}>
              Connect
              <ArrowRightIcon />
            </button>
          )}
        </li>
      </ul>
    );
  };

  return (
    <div
      className={`main-header-container ${
        showMobileSearch && "mobile-search"
      } ${hideSearchBar && "hide-search-bar"}`}
    >
      <div className="mobile">
        <div className="header-container">
          <button onClick={handleOpenSidebar}>
            <HamburgerIcon />
          </button>
          <button className="btn-logo" onClick={navigateToHome}>
            <MobileLogo />
          </button>
          {!hideSearchBar && (
            <button className="btn-search" onClick={handleSearch}>
              <MobileSearchIcon />
            </button>
          )}
          {!isLoggedIn && (
            <button className="btn-connect" onClick={handleConnectWallet}>
              Connect
              <ArrowRightIcon />
            </button>
          )}
        </div>

        {showMobileSearch && (
          <div className="search-bar-container">
            <SearchBar />
          </div>
        )}
      </div>
      <div className="tablet-desktop">
        <div className="tablet header-container">
          <button onClick={handleOpenSidebar}>
            <HamburgerIcon />
          </button>
          <button className="btn-logo" onClick={navigateToHome}>
            <MobileLogo />
          </button>

          {!hideSearchBar && (
            <div className="search-bar-container">
              <SearchBar />
            </div>
          )}
        </div>

        <div className="desktop desktop-container">
          <div className="logo">
            <button onClick={navigateToHome}>
              <DarkLogo />
            </button>
          </div>

          {!hideSearchBar && (
            <div className="search-bar-container">
              <SearchBar />
            </div>
          )}
        </div>

        {renderMenuContainer()}
      </div>
      <Connector
        clicked={clickHandleConnect}
        setClicked={setClickHandleConnect}
      />
    </div>
  );
};

export default Header;
