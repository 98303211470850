// Theme
export class ThemeTypes {
  static dark = "dark";
  static light = "light";
  static defaultTheme = this.dark;
}

// Dex Types
export class DexTypes {
  static sushiswap = "SushiSwap V2";
  static uniswapV2 = "Uniswap V2";
  static uniswapV3 = "Uniswap V3";
  static gmx = "GMX";
  static pancakeSwapV2 = "PancakeSwap V2";
  static pancakeSwapV3 = "PancakeSwap V3";
  static biswap = "Biswap";
  static curve = "Curve";
  static camelotV3 = "Camelot V3";
  static camelotV2 = "Camelot V2";
  static lfj_v1 = "LFJ V1";
  static platypus = "Platypus";
  static pangolin = "Pangolin";
  static quickswapV3 = "Quickswap V3";
  static baseswap = "Baseswap";
  static balancerV2 = "Balancer V2";
  static velodrome = "Velodrome";
  static velodromeSlipstream = "Velodrome Slipstream";
  static beethovenX = "Beethoven X";
  static aerodrome = "Aerodrome";
  static aerodromeSlipstream = "Aerodrome Slipstream";
  static vvsFinance = "VVS Finance";
  static ramses = "Ramses";
  static raydium = "Raydium";
  static meteora = "Meteora";
  static orca = "Orca";
}

// Various Tab Options
export class TokenGeniusTabOptions {
  static dexTransactions = "dex_transactions";
  static topHolders = "top_holders";
  static topAccumulators = "top_accumulators";
  static topDexTraders = "top_dex_traders";
  static whaleTransactions = "whale_transactions";
  static intel = "intel";
  static smartMoney = "smart_money";
}

export class WatchlistTabOptions {
  static wallet = "Wallet Address";
  static token = "Token";
  static nft = "NFT";
}

export class WalletProfileTabOptions {
  static dex = "dex";
  static nft = "nft";
}

export class WalletTransactionTabOptions {
  static dexTransactions = "dex_transactions";
  static erc20TokenTransfers = "erc20_token_transfers";
  static externalTransactions = "external_transactions";
  static internalTransactions = "internal_transactions";
  static allStreams = "all_streams";
}

export class SmartAlertNotifyTabOptions {
  static telegram = "telegram";
  static slack = "slack";
  static discord = "discord";
}

export class SmartAlertTypeTabOptions {
  static Wallet = "wallet";
  static Token = "token";
}

export class SmartAlertEventTypeOptions {
  static all = "all";
  static trade = "trade";
  static tokenTransfer = "token_transfer";
}

export class WalletTokenDistTabOptions {
  static amount = "balance";
  static ethValue = "eth_value";
  static usdValue = "usd_value";
}

// Pagination Size Items
export const SmallPageSizeItems = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
];

export const MediumPageSizeItems = [
  // Make this to be divided by 10K always
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
];

export const LargePageSizeItems = [
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];

// Chains
export class Chains {
  static All = "all";
  static Ethereum = "ethereum";
  static Arbitrum = "arbitrum";
  static Binance = "binance";
  static Polygon = "polygon";
  static Avalanche = "avalanche";
  static Base = "base";
  static Optimism = "optimism";
  static Fantom = "fantom";
  static Cronos = "cronos";
  static Solana = "solana";
}

export const ChainSelectorItems = [
  {
    label: "All Chains",
    value: Chains.All,
  },
  {
    label: "Ethereum",
    value: Chains.Ethereum,
  },
  {
    label: "Base",
    value: Chains.Base,
  },
  {
    label: "Binance Chain",
    value: Chains.Binance,
  },
  {
    label: "Arbitrum",
    value: Chains.Arbitrum,
  },
  {
    label: "Polygon",
    value: Chains.Polygon,
  },
  {
    label: "Optimism",
    value: Chains.Optimism,
  },
  {
    label: "Avalanche",
    value: Chains.Avalanche,
  },
  {
    label: "Solana",
    value: Chains.Solana,
  },
];

export const SmartTokenAlertChains = [
  Chains.Base,
  Chains.Ethereum,
  Chains.Solana,
  Chains.Binance,
];

export class TokenAgeCategories {
  static LESS_THAN_1_HOUR = "lt-1h";
  static BETWEEN_1_AND_3_HOURS = "1h-3h";
  static BETWEEN_3_AND_6_HOURS = "3h-6h";
  static BETWEEN_6_AND_24_HOURS = "6h-24h";
  static BETWEEN_1_AND_2_DAYS = "1d-2d";
  static BETWEEN_2_AND_3_DAYS = "2d-3d";
  static BETWEEN_3_AND_7_DAYS = "3d-7d";
  static MORE_THAN_7_DAYS = "mt-7d";
}

export const TokenAgeSelectorItems = [
  { label: "Less than 1H", value: TokenAgeCategories.LESS_THAN_1_HOUR },
  { label: "1H to 3H", value: TokenAgeCategories.BETWEEN_1_AND_3_HOURS },
  { label: "3H to 6H", value: TokenAgeCategories.BETWEEN_3_AND_6_HOURS },
  { label: "6H to 24H", value: TokenAgeCategories.BETWEEN_6_AND_24_HOURS },
  { label: "1D to 2D", value: TokenAgeCategories.BETWEEN_1_AND_2_DAYS },
  { label: "2D to 3D", value: TokenAgeCategories.BETWEEN_2_AND_3_DAYS },
  { label: "3D to 7D", value: TokenAgeCategories.BETWEEN_3_AND_7_DAYS },
  { label: "More than 7D", value: TokenAgeCategories.MORE_THAN_7_DAYS },
];

export const NotSupportChains = [Chains.Fantom];

//User SubScrType
export class SubScrType {
  static Starter = 1;
  static Plus = 2;
  static Nexus = 3;
}

//Badges
export class BadgeType {
  static Luminary = "luminary";
  static Sentinel = "sentinel";
  static Vanguard = "vanguard";
  static Omega = "omega";
  static Alpha = "alpha";
}

export class AddressType {
  static Wallet = "wallet";
  static Token = "token";
  static TxHash = "tx_hash";
}

export const NotSupportChainToast =
  "We don't support this chain at the moment.";
export const NotValidTokenAddressToast =
  "The token address is not a valid contract address.";

//Threshold
export const ThresholdType = [
  { icon: "shrimp", label: "Shrimp ( >$5K+ )", value: 5000 },
  { icon: "fish", label: "Fish ( >$10K+ )", value: 10000 },
  { icon: "dolphin", label: "Dolphin ( >$30K+ )", value: 30000 },
  { icon: "whale", label: "Whale ( >$50K+ ) ", value: 50000 },
  { icon: "giga_whale", label: "Giga Whale ( >$100K+ )", value: 100000 },
];

//Dex Transaction Type
export class DexTransactionType {
  static TokenDex = "token_dex";
  static TokenDexWhales = "token_dex_whales";
  static TGSmartMoney = "tg_smart_money";
  static WhaleTrades = "whale_trades";
  static LiveDexMaster = "live_dex_master";
  static WalletDex = "wallet_dex";
  static SmartMoney = "smart_money";
}

export class PeriodType {
  static Daily = "daily";
  static Weekly = "weekly";
  static Monthly = "monthly";
}

export const TutorialVideoID = "P8iNLV4CyDo";

export const TradeType = [
  { label: "Buy", value: "buy" },
  { label: "Sell", value: "sell" },
];

export class CexDirections {
  static In = "in";
  static Out = "out";
}

export class TokenInfoTabOptions {
  static general = "general";
  static chart = "chart";
}

export class DexFlowsTabOptions {
  static Total = "total";
  static Smart = "smart";
}

export class SmartFlowsTabOptions {
  static Top = "top";
  static Net = "net";
}

export class DexFlowsStatsTabOptions {
  static Buy = "buy";
  static Sell = "sell";
  static Net = "net";
}

export class UnlockType {
  static Create = "create";
  static CreateAndUpgrade = "create_upgrade";
  static Upgrade = "upgrade";
  static Main = "main";
  static DiscoveryAlpha = "discovery_alpha";
  static Nexus = "nexus";
}

export class OnChainAITabOptions {
  static Overall = "overall";
  static Advanced = "advanced";
}

export class WhaleDexStreamOptions {
  static Buying = "buy";
  static Selling = "sell";
  static Total = "total";
}
