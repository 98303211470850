import {
  IChainQuery,
  IGetSmartHoldingsParams,
  IGetSmartOverallHoldingsParams,
} from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

export class SmartHoldingsAPI {
  static getSmartOverallHoldings = async ({
    chain,
  }: IGetSmartOverallHoldingsParams) => {
    return BaseAPI.callAPI({
      url: "/wallet/smart_money/overall_holdings/",
      params: {
        chain,
      },
    });
  };

  static getSmartHoldings = async ({
    chain,
    page,
    page_size,
    metric,
    ordering = "",
    exclude_sn_tokens,
  }: IGetSmartHoldingsParams) => {
    return BaseAPI.callAPI({
      url: "/wallet/smart_holdings/",
      params: {
        chain,
        metric,
        page,
        page_size,
        ordering,
        exclude_sn_tokens,
      },
    });
  };

  static getStableCoinHoldings = async ({ chain }: IChainQuery) => {
    return BaseAPI.callAPI({
      url: "/wallet/smart_money/stable_coin_holdings/",
      params: {
        chain,
      },
    });
  };

  static getNetFlowOverall = async ({ chain }: IChainQuery) => {
    return BaseAPI.callAPI({
      url: "/dex/statistics/sm_dex_overall_flows/",
      params: {
        chain,
      },
    });
  };

  static getTopDexDailyFlows = async ({ chain }: IChainQuery) => {
    return BaseAPI.callAPI({
      url: "/dex/statistics/sm_top_dex_daily_flows/",
      params: {
        chain,
      },
    });
  };
}
