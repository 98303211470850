import {
  IGetMarketPerformanceParams,
  IGetTokenInfoParams,
  IGetTokenPriceChartParams,
  IUpdateCommunityScoreParams,
} from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

export class TokenInfoAPI {
  static getTokenInfo = async ({
    chain,
    token_address,
  }: IGetTokenInfoParams) => {
    return BaseAPI.callAPI({
      url: `/token/${token_address}/info/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getSmartMoneyInfo = async ({
    chain,
    token_address,
  }: IGetTokenInfoParams) => {
    return BaseAPI.callAPI({
      url: `/token/${token_address}/smdt_data/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getMarketInfo = async ({
    chain,
    token_address,
  }: IGetTokenInfoParams) => {
    return BaseAPI.callAPI({
      url: `/token/${token_address}/market/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getMarketCapInfo = async ({
    chain,
    token_address,
  }: IGetTokenInfoParams) => {
    return BaseAPI.callAPI({
      url: `/token/${token_address}/market_cap/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getAuditInfo = async ({
    chain,
    token_address,
  }: IGetTokenInfoParams) => {
    return BaseAPI.callAPI({
      url: `/token/${token_address}/audits/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getMarketPerformance = async ({
    chain,
    token_address,
    period,
  }: IGetMarketPerformanceParams) => {
    return BaseAPI.callAPI({
      url: `/token/${token_address}/market_performance/`,
      params: {
        chain,
        period,
      },
      needToken: false,
    });
  };

  static getAllTokens = async () => {
    return BaseAPI.callAPI({
      url: "/token/all/",
      needToken: false,
    });
  };

  static getTokenPriceHistory = async ({
    chain,
    token_address,
  }: IGetTokenInfoParams) => {
    return BaseAPI.callAPI({
      url: `/token/price_chart/`,
      params: {
        chain,
        token_address,
        resolution: "4h",
        from_ts: Math.floor(new Date().getTime() / 1000) - 86400 * 7,
        to_ts: Math.floor(new Date().getTime() / 1000),
      },
      needToken: false,
    });
  };

  static checkAddress = async ({
    chain,
    token_address,
  }: IGetTokenInfoParams) => {
    return BaseAPI.callAPI({
      url: `/check/address/${token_address}/`,
      method: "POST",
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getPriceChart = async ({
    chain,
    token_address,
    resolution,
    metric,
    from_ts,
    to_ts,
  }: IGetTokenPriceChartParams) => {
    return BaseAPI.callAPI({
      url: `/token/price_chart/`,
      params: {
        chain,
        token_address,
        resolution,
        metric,
        from_ts,
        to_ts,
      },
      needToken: false,
    });
  };

  static getCommunityScores = async ({
    chain,
    token_address,
  }: IGetTokenInfoParams) => {
    return BaseAPI.callAPI({
      url: `/token/${token_address}/community_scores/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static updateCommunityScores = async ({
    chain,
    token_address,
    metric,
  }: IUpdateCommunityScoreParams) => {
    return BaseAPI.callAPI({
      url: `/token/${token_address}/community_scores/update/`,
      method: "post",
      data: {
        chain,
        metric,
      },
      needToken: false,
    });
  };
}
