import React, { useState } from "react";
import { AuthHelpers } from "../../helpers/auth";
import { UnlockType } from "../../utils/constants";
import { AppRoutes } from "../../utils/routes";
import Connector from "../Common/Connector";
import ConnectIcon from "../Icons/ConnectIcon";
import LockIcon from "../Icons/LockIcon";
import UpgradeIcon2 from "../Icons/UpgradeIcon2";

import "./scss/LockedTab.scss";

interface ILockedTab {
  type?: string;
  route?: string;
}

const LockedTab = ({ type, route = "" }: ILockedTab) => {
  const [clickHandleConnect, setClickHandleConnect] = useState(false);

  const isLoggedIn = AuthHelpers.isUserLoggedIn();

  const getTypeFromRoute = () => {
    if (
      route.startsWith(AppRoutes.plan) ||
      route.startsWith(AppRoutes.profile) ||
      route.startsWith(AppRoutes.watchlist) ||
      route.startsWith(AppRoutes.smartAlert) ||
      route.startsWith(AppRoutes.leaderBoard) ||
      route.startsWith(AppRoutes.whaleTrades) ||
      route.startsWith(AppRoutes.onchainAI) ||
      route.startsWith(AppRoutes.aiAgentGenius) ||
      route.startsWith(AppRoutes.latestUpdates) ||
      route.startsWith(AppRoutes.trainingHub) ||
      route.startsWith(AppRoutes.nomicsPulse)
    )
      return UnlockType.Main;
    if (
      route.startsWith(AppRoutes.narrativePulse) ||
      route.startsWith(AppRoutes.smartDexStream) ||
      route.startsWith(AppRoutes.smartHoldings) ||
      route.startsWith(AppRoutes.dexFlows) ||
      route.startsWith(AppRoutes.cexNetTransactions)
    )
      return UnlockType.DiscoveryAlpha;
  };

  const lockedType = type ?? getTypeFromRoute();

  return (
    <div className={`locked-tab ${route ? "" : "has-bg"}`}>
      <div className="unlock-content">
        <LockIcon />
        {lockedType === UnlockType.Create &&
          "Create a free account to unlock this."}
        {lockedType === UnlockType.CreateAndUpgrade &&
          "Create & Upgrade your account to unlock this data."}
        {lockedType === UnlockType.Nexus &&
          "Only NEXUS accounts will be able to unlock this data."}
        {lockedType === UnlockType.Upgrade &&
          "Upgrade your account to unlock this data."}
        {lockedType === UnlockType.Main &&
          `${
            isLoggedIn ? "Upgrade" : "Create"
          } your account to access this section.`}
        {lockedType === UnlockType.DiscoveryAlpha &&
          "Create & Upgrade your account to unlock this section."}
      </div>
      <div className="not-available">
        {lockedType === UnlockType.Create &&
          "This data is not available without an account."}
        {lockedType === UnlockType.CreateAndUpgrade &&
          "This data is not available without the correct account."}
        {lockedType === UnlockType.Nexus &&
          "This data is not available without the correct acccess."}
        {lockedType === UnlockType.Upgrade &&
          "This data is not available with your current account."}
        {lockedType === UnlockType.Main &&
          "Alphanomics is an easy to use on-chain DEX data terminal which simplifies blockchain on-chain activities to provide fast, real time, cleaned & curated insights to help uncover new opportunities, token trends and actionable insights for your trading and investments."}
        {lockedType === UnlockType.DiscoveryAlpha &&
          "Discovery Alpha is our exclusive section that unlocks real time alpha on what the smartest money are doing on-chain in crypto - See everything from portfolio holdings, accumulations, CEX withdrawals or deposits and see what fresh money is doing.\n" +
            "You will need an Alphanomics account."}
      </div>
      {lockedType === UnlockType.Upgrade && (
        <a
          className="btn-upgrade"
          href={AppRoutes.getExactRoute(AppRoutes.plan)}
        >
          <UpgradeIcon2 /> Upgrade
        </a>
      )}
      {(lockedType === UnlockType.Create ||
        lockedType === UnlockType.CreateAndUpgrade ||
        lockedType === UnlockType.Nexus) && (
        <>
          {isLoggedIn ? (
            <a
              className="btn-upgrade"
              href={AppRoutes.getExactRoute(AppRoutes.plan)}
            >
              <UpgradeIcon2 /> Upgrade
            </a>
          ) : (
            <a
              className="btn-upgrade"
              onClick={() => setClickHandleConnect(true)}
            >
              <ConnectIcon /> Connect
            </a>
          )}
        </>
      )}
      {(lockedType === UnlockType.Main ||
        lockedType === UnlockType.DiscoveryAlpha) && (
        <>
          {isLoggedIn ? (
            <a
              className="btn-upgrade-new"
              href={AppRoutes.getExactRoute(AppRoutes.plan)}
            >
              Upgrade Account
            </a>
          ) : (
            <a
              className="btn-upgrade-new"
              onClick={() => setClickHandleConnect(true)}
            >
              Create & Connect Account
            </a>
          )}
        </>
      )}
      <Connector
        clicked={clickHandleConnect}
        setClicked={setClickHandleConnect}
      />
    </div>
  );
};

export default LockedTab;
