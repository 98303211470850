import { useWeb3Modal } from "@web3modal/wagmi/react";

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UserRejectedRequestError } from "viem";
import { mainnet } from "viem/chains";
import { useAccount, useSignMessage, useSwitchChain } from "wagmi";

import { authActions } from "../../redux/features/authSlice";
import { snackbarActions } from "../../redux/features/snackbarSlice";

interface IConnectorParams {
  clicked: boolean;
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
}
const Connector = ({ clicked, setClicked }: IConnectorParams) => {
  const dispatch = useDispatch();
  const { open } = useWeb3Modal();

  const chainId = mainnet.id;
  const { chainId: currentChain } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const { data, isSuccess, signMessageAsync } = useSignMessage();
  const { address, isConnected } = useAccount();

  const handleSignMessage = async () => {
    try {
      if (currentChain !== chainId) await switchChainAsync({ chainId });
      await signMessageAsync({
        message: "Login to Alphanomics",
      });
    } catch (error) {
      if (error instanceof UserRejectedRequestError) {
        console.warn("User rejected the request.");
        dispatch(
          snackbarActions.showSnackBar({
            content: "User rejected request.",
            color: "warning",
          })
        );
      } else {
        console.error(error);
        dispatch(
          snackbarActions.showSnackBar({
            content: "Something went wrong.",
            color: "error",
          })
        );
      }
    } finally {
      setClicked(false);
    }
  };

  const handleConnectWallet = async () => {
    if (!isConnected) await open();
    else await handleSignMessage();
  };

  useEffect(() => {
    if (clicked) handleConnectWallet();
  }, [clicked]);

  useEffect(() => {
    if (clicked && isConnected) handleSignMessage();
  }, [isConnected]);

  useEffect(() => {
    if (isSuccess) {
      const ref_code = new URLSearchParams(window.location.search).get(
        "ref_code"
      );

      dispatch(
        authActions.signin({
          wallet_address: address as string,
          signature: data as string,
          ref_code: ref_code,
        })
      );
    }
  }, [isSuccess]);

  return <></>;
};

export default Connector;
