import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ISmartHoldings,
  ISmartHoldingsMetric,
  ISmartHoldingsQuery,
} from "../../@types/redux";
import { SmartHoldingsAPI } from "../../utils/api/SmartHoldingsAPI";

interface ISmartHoldingsState {
  isRefreshing: boolean;
  loading: boolean;
  total_count: number;
  total_page: number;
  results: [];
  max_values: {
    changed_1d: number;
    changed_3d: number;
    changed_7d: number;
    changed_14d: number;
    changed_30d: number;
  };
  query: ISmartHoldingsQuery;
}

export const smartHoldingsDefaultQuery = {
  chain: "",
  page_size: 25,
  page: 1,
  metric: "All",
  ordering: "",
  exclude_sn_tokens: true,
};

const initialState: ISmartHoldingsState = {
  isRefreshing: false,
  loading: false,
  total_count: 0,
  total_page: 0,
  max_values: {
    changed_1d: 0,
    changed_3d: 0,
    changed_7d: 0,
    changed_14d: 0,
    changed_30d: 0,
  },
  results: [],
  query: { ...smartHoldingsDefaultQuery },
};

const smartHoldingSlice = createSlice({
  name: "smartHoldings",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<ISmartHoldingsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.results = [];
      }
    },

    setList: (state, action: PayloadAction<ISmartHoldings>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total_count = action.payload.total_count;
      state.total_page = action.payload.total_page;
      state.results = action.payload.results;

      state.max_values = action.payload.results.reduce(
        (acc, result: any) => ({
          changed_1d: Math.max(
            acc.changed_1d,
            Math.abs(result.changed_1d || 0)
          ),
          changed_3d: Math.max(
            acc.changed_3d,
            Math.abs(result.changed_3d || 0)
          ),
          changed_7d: Math.max(
            acc.changed_7d,
            Math.abs(result.changed_7d || 0)
          ),
          changed_14d: Math.max(
            acc.changed_14d,
            Math.abs(result.changed_14d || 0)
          ),
          changed_30d: Math.max(
            acc.changed_30d,
            Math.abs(result.changed_30d || 0)
          ),
        }),
        {
          changed_1d: 0,
          changed_3d: 0,
          changed_7d: 0,
          changed_14d: 0,
          changed_30d: 0,
        }
      );
    },

    setQuery: (state, action: PayloadAction<ISmartHoldingsQuery>) => {
      state.query = action.payload;
    },

    setMetric: (state, action: PayloadAction<ISmartHoldingsMetric>) => {
      state.query.metric = action.payload.metric;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...smartHoldingsDefaultQuery,
        metric: state.query.metric,
      };
    },
  },
});

const smartHoldingsAction = smartHoldingSlice.actions;
const smartHoldingsReducer = smartHoldingSlice.reducer;

export { smartHoldingsAction, smartHoldingsReducer };

// Sagas

function* getSmartHoldings(action: PayloadAction<ISmartHoldingsQuery>): any {
  const res = yield call(SmartHoldingsAPI.getSmartHoldings as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      smartHoldingsAction.setList({
        ...res.data,
      })
    );
  } else {
    yield put(
      smartHoldingsAction.setList({
        total_count: 0,
        total_page: 0,
        results: [],
      })
    );
  }
}

export function* smartHoldingsSaga() {
  yield all([takeLatest(smartHoldingsAction.getList, getSmartHoldings)]);
}
